<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="boxWrap">
      <div class="mb-10">
        下单毛重/毛单价：{{ info.original_gross_weight || "-" }}斤 【
        {{
          info.isCount
            ? tool.toDecimal2(
                (info.original_gross_weight_price * 10000) / 100 / 10000
              )
            : info.original_gross_weight_price || "-"
        }}元/斤】
      </div>
      <div class="mb-10">
        下单净重/净单价：{{ info.original_weight || "-" }}斤 【
        {{
          info.isCount
            ? tool.toDecimal2(
                (info.original_weight_price * 10000) / 100 / 10000
              )
            : info.original_weight_price || "-"
        }}元/斤】
      </div>
      <div class="mb-10">
        实际毛重/毛单价：{{ info.gross_weight || "-" }}斤 【
        {{
          info.isCount
            ? tool.toDecimal2((info.gross_weight_price * 10000) / 100 / 10000)
            : info.gross_weight_price
        }}元/斤】
      </div>
      <div class="mb-10">
        实际净重/净单价：{{ info.weight || "-" }}斤 【
        {{
          info.isCount
            ? tool.toDecimal2((info.weight_price * 10000) / 100 / 10000)
            : info.weight_price
        }}元/斤】
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "costConfigDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loading: true,
      listData: [],
      info: {},
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "data-===");
      this.info = data;
      this.dialogVisible = true;
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .boxWrap {
    font-weight: 500;
    font-size: 14px;
  }
  /deep/ .el-dialog__header {
    height: 20px !important;
  }
  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
</style>
