var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "",
        visible: _vm.dialogVisible,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c("div", { staticClass: "boxWrap" }, [
        _c("div", { staticClass: "mb-10" }, [
          _vm._v(
            " 下单毛重/毛单价：" +
              _vm._s(_vm.info.original_gross_weight || "-") +
              "斤 【 " +
              _vm._s(
                _vm.info.isCount
                  ? _vm.tool.toDecimal2(
                      (_vm.info.original_gross_weight_price * 10000) /
                        100 /
                        10000
                    )
                  : _vm.info.original_gross_weight_price || "-"
              ) +
              "元/斤】 "
          ),
        ]),
        _c("div", { staticClass: "mb-10" }, [
          _vm._v(
            " 下单净重/净单价：" +
              _vm._s(_vm.info.original_weight || "-") +
              "斤 【 " +
              _vm._s(
                _vm.info.isCount
                  ? _vm.tool.toDecimal2(
                      (_vm.info.original_weight_price * 10000) / 100 / 10000
                    )
                  : _vm.info.original_weight_price || "-"
              ) +
              "元/斤】 "
          ),
        ]),
        _c("div", { staticClass: "mb-10" }, [
          _vm._v(
            " 实际毛重/毛单价：" +
              _vm._s(_vm.info.gross_weight || "-") +
              "斤 【 " +
              _vm._s(
                _vm.info.isCount
                  ? _vm.tool.toDecimal2(
                      (_vm.info.gross_weight_price * 10000) / 100 / 10000
                    )
                  : _vm.info.gross_weight_price
              ) +
              "元/斤】 "
          ),
        ]),
        _c("div", { staticClass: "mb-10" }, [
          _vm._v(
            " 实际净重/净单价：" +
              _vm._s(_vm.info.weight || "-") +
              "斤 【 " +
              _vm._s(
                _vm.info.isCount
                  ? _vm.tool.toDecimal2(
                      (_vm.info.weight_price * 10000) / 100 / 10000
                    )
                  : _vm.info.weight_price
              ) +
              "元/斤】 "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }